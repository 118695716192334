import { Component, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { MtTranslateManagerService } from '../../../../../../core/translate/services/mt-translate-manager.service';
import { Router } from '@angular/router';

@Component({
  selector: 'lmt-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {
  protected languageModels = {
    en: {
      key: 'en',
      icon: 'https://lmtgmbh.de/wp-content/plugins/sitepress-multilingual-cms/res/flags/en.png',
      name: 'app.header.languages.en'
    },
    de: {
      key: 'de',
      icon: 'https://lmtgmbh.de/wp-content/plugins/sitepress-multilingual-cms/res/flags/de.png',
      name: 'app.header.languages.de'
    }
  };

  public currentLang = this.languageModels['en'];

  constructor(private mtTranslateManagerService: MtTranslateManagerService
  ) { }

  ngOnInit() {
    this.mtTranslateManagerService.onChangeLang
    .pipe(untilDestroyed(this))
    .subscribe(lang => {
      this.currentLang = this.languageModels[lang];
      this.setWordpressLanguage(lang);

      const mapping = {
        de: { from: "/productworld", to: "/produktwelt" },
        en: { from: "/produktwelt", to: "/productworld" },
      };

      const routeMapping = mapping[lang];
      if (routeMapping && location.href.includes(routeMapping.from)) {
        location.href = location.href.replace(routeMapping.from, routeMapping.to);
      }
    });

    if (!this.evaluateWordpressLanguage())
      this.currentLang = this.languageModels[this.mtTranslateManagerService.getCurrentLang()] || this.languageModels['en'];
  }

  public get languageList() {
    return Object.values(this.languageModels).filter((lang) => {
      return lang.key !== this.currentLang.key;
    });
  }

  private setLanguageCookie(name: string, value: string, days: number = 7) {
  }

  public loadScript() {
      console.log('preparing to load...');
      const node = document.createElement('script');
      node.src = 'https://lmtgmbh.de/wp-content/uploads/dynamic_avia/avia-footer-scripts-2886a65d1cc2737242edca7cabe7eba2---5f3bdec3452b3.js';
      node.type = 'text/javascript';
      node.async = true;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
  }

  private setWordpressLanguage(lang: string) {
    const date = new Date();
    const expiryDays = 365;
    date.setTime(date.getTime() + (expiryDays * 24 * 60 * 60 * 1000));
    document.cookie = `wp-wpml_current_language=${lang}; expires=${date.toUTCString()}; path=/`;
  }

  private evaluateWordpressLanguage() {
    const cookieName = "wp-wpml_current_language";
    const cookiePair = document.cookie
      .split("; ")
      .find(row => row.startsWith(`${cookieName}=`));
    if (!cookiePair)
      return false;
    const cookieValue = cookiePair.split("=")[1];
    if (cookieValue && this.languageModels[cookieValue]) {
      this.currentLang = this.languageModels[cookieValue];
      this.mtTranslateManagerService.changeLang(cookieValue);
      return true;
    }
    else
      return false;
  }

  public onClickLanguage(e, lang: string) {
    e.preventDefault();
    e.stopPropagation();
    this.mtTranslateManagerService.changeLang(lang);
  }

  ngOnDestroy() {
  }

}
