import { Injectable } from '@angular/core';
import { MtTranslateManagerService } from '../translate/services/mt-translate-manager.service';

@Injectable()
export class Mapper {
  constructor(private translateService: MtTranslateManagerService) { }

  private fieldForMapping = [
  'Headline',
  'Highlights',
  'Teaser',
  'Name',
  'ShortDescriptionRich',
  'shortDescription',
  'shortName',
  'webmetaDescription',
  'webmetaTitle',
  'webmetaSlug',
  'furtherInformation',
  'Banner_Link'
];

  public lang<T>(list: any[]): T[] {

    const newList: T[] = [];
    list.forEach((element) => {
      newList.push(this.langOneObject(element));
    });

    return newList;
  }

  public langOneObject<T>(element: any): T {
    const postfixLang = '_' + this.translateService.getCurrentLang().toLocaleUpperCase();

    const newElement: any = {};

    this.getFields(Object.getOwnPropertyNames(element), this.fieldForMapping).forEach(field => {
      if (Object.prototype.toString.call(element[field]) === '[object Object]') {
        element[field] = this.langOneObject(element[field]);
      }

      if (Array.isArray(element[field])) {
        element[field] = this.lang(element[field]);
      }

      newElement[field] = element[field];
    });

    this.fieldForMapping.forEach(field => {
      const langField = field + postfixLang;
      if (element[langField] !== undefined) {
        newElement[field] = element[langField] || newElement[field] || '';
      } 
    });

    return newElement;
  }

  private getFields(fields: string[], fieldsForRemove: string[]): string[] {
    const newFields = fields;
    fieldsForRemove.forEach(fieldForRemove => {
      let i = 0;
      while (i !== newFields.length) {
        if (newFields[i].includes(fieldForRemove + '_')) {
          newFields.splice(i, 1);
        } else {
          i++;
        }
      }
    });
    return newFields;
  }


}
