import { NgModule } from '@angular/core';
import { MessageBusService } from './message-bus-service';


@NgModule({
    imports: [
    ],
    declarations: [

    ],
    entryComponents: [],
    exports: [
    ],
    providers: [MessageBusService],
})
export class MessageBusModule {

}
