import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';


interface BaseMessage {
    channel: string;
    data: any;
}

interface Message<T> extends BaseMessage {
    data: T;
}

@Injectable()
export class MessageBusService {
    private channels: Record<string, Subject<BaseMessage>> = {};
    private syncChannels: Record<string, ReplaySubject<BaseMessage>> = {};

    public publish<T>(message: T): void {
        const type = (<any> message.constructor);
        const channelName = (<any> message).channel || type.channel || type.name;

        const channel = this.getChannel<T>(channelName);
        const syncChannel = this.getSyncChannel<T>(channelName);
        const sendMessage: Message<T> = { channel: channelName, data: message };

        channel.next(sendMessage);
        syncChannel.next(sendMessage);
    }

    public publishToChannel<T>(channelName: string, message: T): void {
        const channel = this.getChannel<T>(channelName);
        const syncChannel = this.getSyncChannel<T>(channelName);
        const sendMessage: Message<T> = { channel: channelName, data: message };

        channel.next(sendMessage);
        syncChannel.next(sendMessage);
    }

    public of<T>(messageType: { new(...args: any[]): T }): Observable<T> {
        const type = (<any> messageType);
        const channelName = type.channel || type.name;
        const channel = this.getChannel<T>(channelName);

        return channel
            .pipe(map(m => m.data));
    }

    public sync<T>(messageType: { new(...args: any[]): T }): Observable<T> {
        const type = (<any> messageType);
        const channelName = type.channel || type.name;
        const channel = this.getSyncChannel<T>(channelName);

        return channel
            .pipe(map(m => m.data));
    }

    protected getChannel<T>(name: string): Subject<Message<T>> {
        const channel = this.channels[name] || new Subject<Message<T>>();
        this.channels[name] = channel;
        return channel;
    }

    protected getSyncChannel<T>(name: string): ReplaySubject<Message<T>> {
        const channel = this.syncChannels[name] || new ReplaySubject<Message<T>>(1);
        this.syncChannels[name] = channel;
        return channel;
    }

}
