import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MtTranslateManagerService } from '../services/mt-translate-manager.service';

@Pipe({
    name: 'mtDateLocalized',
})
export class MtDateLocalizedPipe implements PipeTransform {
    constructor(
        private translateManager: MtTranslateManagerService,
    ) {
    }

    public transform(value: string, pattern: string): string {
        const currentLocale = this.translateManager.getCurrentLang();
        return new DatePipe(currentLocale).transform(value, pattern);
    }
}
