import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';

export interface TranslateStore {
    [key: string]: string | TranslateStore;
}

export interface TranslateManager {
    createTranslation(lang: string, translation: TranslateStore): Observable<TranslateStore>;
}

@Injectable({
    providedIn: 'root',
})
export class MtTranslateManagerService implements TranslateManager {

    public onChangeLang: Observable<string>;

    constructor(
        private translateService: TranslateService
    ) {
        this.onChangeLang = this.translateService
            .onLangChange
            .pipe(
                map((event: LangChangeEvent) => event.lang),
                untilDestroyed(this, 'destroy')
            );
    }

    public changeLang(lang: string): void {
        this.translateService.use(lang);
        localStorage.setItem('current-language', lang);
    }

    public getDefaultLang(): string {
        return localStorage.getItem('current-language') || 'en';
    }

    public getCurrentLang(): string {
        return this.translateService.currentLang || this.getDefaultLang();
    }

    public getBaseTranslations(lang: string): TranslateStore {
        return this.translateService.translations[lang];
    }

    public createTranslation(lang: string, translation: TranslateStore): Observable<TranslateStore> {
        return of(Object.assign({}, this.getBaseTranslations(lang) || {}, translation));
    }

    destroy() {
        // To protect you, we'll throw an error if it doesn't exist.
    }
}
