import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lmt-app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
