import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MtTranslateService } from './services/mt-translate.service';
import { MtTranslateManagerService } from './services/mt-translate-manager.service';
import { LangInterceptor } from './interceptors/lang.interceptor';
import { MtDateLocalizedPipe } from './pipes';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        MtDateLocalizedPipe,
    ],
    exports: [
        MtDateLocalizedPipe,
    ],
    providers: [
        MtTranslateService,
        {
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: (translateService: MtTranslateService, translateManager: MtTranslateManagerService) => {
                return () => {
                    return new Promise(resolve => {
                        translateService.startObserve(translateManager.onChangeLang);
                        translateService.use(translateManager.getCurrentLang())
                            .subscribe(resolve);
                    });
                };
            },
            deps: [MtTranslateService, MtTranslateManagerService],
        }, {
            provide: HTTP_INTERCEPTORS,
            useClass: LangInterceptor,
            multi: true,
        },
    ],
})
export class MtTranslateModule {
}
