import { HttpClient } from '@angular/common/http';
import { mergeMap } from 'rxjs/operators';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { TranslateManager, TranslateStore } from './mt-translate-manager.service';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

export type RootLoader = (http: HttpClient) => MultiTranslateHttpLoader;
export type ChildLoader = (appTranslateStore: TranslateManager, http: HttpClient) => MtTranslateHttpLoader;

export class MtTranslateHttpLoader implements TranslateLoader {

    public static createLoaderForRoot(...urls: Array<{ prefix: string, suffix: string }>): RootLoader {
        return (http: HttpClient): MultiTranslateHttpLoader => {
            return new MultiTranslateHttpLoader(http, [
                ...urls,
            ]);
        };
    }

    public static createLoaderForChild(prefix: string): ChildLoader {
        return (translateManager: TranslateManager, http: HttpClient) => {
            return new MtTranslateHttpLoader(translateManager, http, prefix, '.json');
        };
    }

    constructor(
        private store: TranslateManager,
        private http: HttpClient,
        private prefix: string,
        private suffix: string,
    ) {
    }

    public getTranslation(lang: string): Observable<TranslateStore> {
        return this.http.get(this.prefix + lang + this.suffix)
            .pipe(
                mergeMap((result: TranslateStore) => this.store.createTranslation(lang, result))
            );
    }
}
