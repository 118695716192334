import { Injectable } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

export interface TranslationObject {
    [key: string]: string;
}

@Injectable()
export class MtTranslateService {

    private subscriber: Subscription;

    constructor(
        public translateService: TranslateService,
    ) {}

    public startObserve(observer: Observable<string>): void {
        if (this.subscriber) {
            this.subscriber.unsubscribe();
        }
        this.subscriber = observer
            .pipe(
                untilDestroyed(this, 'destroy')
            )
            .subscribe((lang: string) => {
                this.use(lang);
            });
    }

    public use(lang: string): Observable<TranslationObject> {
        return this.translateService.use(lang);
    }

    destroy() {
        // To protect you, we'll throw an error if it doesn't exist.
    }

    public get(key: string | Array<string>, interpolateParams?: Object): Observable<string | any> {
      return this.translateService.get(key, interpolateParams);
    }
}
