import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppHeaderModule } from './shared/components/app-header/app-header/app-header.module';
import { MtTranslateModule } from './core/translate/mt-translate.module';
import { TranslateCompiler, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MtTranslateHttpLoader } from './core/translate/services/mt-translate-http-loader';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BreadcrumbsModule } from '@exalif/ngx-breadcrumbs';
import { GlobalMessageBusService } from './core/message-bus/global-message-bus-service';
import { MessageBusModule } from './core/message-bus/message-bus.module';
import { Mapper } from './core/helpers/mapper';

import * as Hammer from 'hammerjs';
import { HammerGestureConfig } from '@angular/platform-browser';
import { AppFooterModule } from './shared/components/app-footer/app-footer.module';
import { ScrollDispatchModule } from '@angular/cdk/scrolling';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BreadcrumbsModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    MessageBusModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: MtTranslateHttpLoader.createLoaderForRoot(
          {
            prefix: '/assets/i18n/',
            suffix: '.json',
          },
          {
            prefix: '/assets/i18n/shared/',
            suffix: '.json',
          }
        ),
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
    MtTranslateModule,
    AppHeaderModule,
    AppFooterModule,
    ScrollDispatchModule
  ],
  providers: [
    GlobalMessageBusService,
    Mapper,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
