import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class LangInterceptor implements HttpInterceptor {

    constructor(
        private translateService: TranslateService,
    ) {
    }

    public intercept(request: HttpRequest<{}>, next: HttpHandler): Observable<HttpEvent<{}>> {
        request = request.clone({
            setHeaders: {
                'Accept-Language': this.translateService.currentLang || 'en',
            },
        });
        return next.handle(request);
    }
}
